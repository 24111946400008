@font-face {
  font-family: 'Porsche';
  src: local('PorscheRegular'), url('../assets/fonts/porsche.woff2') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Porsche';
  src: local('PorscheSemiBold'), url('../assets/fonts/porsche-semi-bold.woff2') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Porsche';
  src: local('PorscheBold'), url('../assets/fonts/porsche-bold.woff2') format('woff');
  font-weight: bold;
}
